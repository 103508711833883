import styled, { css } from "styled-components";
import { StyledHeading } from "components/core/Typography/Heading/index.style";
import { Col, Container } from "styled-bootstrap-grid";
import Button from "components/core/Button";

export interface PlainHeaderContainerProps {
  maxWidth?: number;
}

export interface DescriptionProps {
  maxWidth?: number;
}

const StyledPlainHeader = styled.section<{ primaryBackground?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;

  ${StyledHeading} {
    color: ${(props) => props.theme.titleColor};

    * {
      color: ${(props) => props.theme.titleColor};
    }
  }

  ${(props) =>
    props.primaryBackground &&
    css`
      ::before {
        top: 0;
        left: 0;
        width: 100%;
        height: 90vh;
        content: "";
        position: absolute;
        background: ${(props) => props.theme.gradientAccentColor};
      }

      ${StyledHeading}, ${Description} {
        color: #fff;
      }
    `}
`;

const Description = styled.p<DescriptionProps>`
  margin: 0;
  font-size: 24px;

  ${(props) =>
    props.maxWidth &&
    `
      max-width: ${props.maxWidth}px;
    `}
`;

const PlainHeaderContainer = styled(Container)<PlainHeaderContainerProps>`
  ${(props) =>
    props.maxWidth &&
    `
      max-width: ${props.maxWidth}px;
    `}
`;

const PreHeader = styled(Col)`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  color: ${(props) => props.theme.accentColor};
`;

const PlainHeaderCenteredCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlainHeaderCol = styled(PlainHeaderCenteredCol)`
  flex-direction: column;
  margin-top: 2em;
`;

const CTAButton = styled(Button)`
  margin-top: 32px;
`;

const ImageContainer = styled.div`
  margin-top: 2em;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
`;

export {
  StyledPlainHeader,
  Description,
  PlainHeaderContainer,
  PlainHeaderCol,
  PlainHeaderCenteredCol,
  CTAButton,
  ImageContainer,
  ButtonContainer,
  PreHeader,
};
