import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import Heading from "components/core/Typography/Heading";

const StyledBlogPost = styled.article`
  padding: 32px 0;
`;

const BlogPostTitle = styled(Heading)`
  display: block;
  font-weight: bold;

  a {
    color: ${(props) => props.theme.titleColor};

    &:hover {
      text-decoration: underline;
    }

    ${media.md`
      font-size: 22px;
      line-height: 1.4;
    `}
  }
`;

const BlogPostDescription = styled.span`
  display: block;
`;

const CoverContainer = styled.div`
  position: relative;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  .gatsby-image-wrapper {
    border-radius: 16px;
    width: 100%;
  }
`;

const BlogPostMetadata = styled.div`
  font-size: 16px;
  padding: 4px 0;

  a {
    font-size: 16px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  * {
    color: #c4c4c4;
  }
`;

const BlogPostDivider = styled.span`
  margin: 0 8px;
`;

const BlogPostDuration = styled.span``;

const BlogPostAuthor = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  margin: 16px 0 0 0;
`;

const BlogPostAuthorImage = styled.div`
  display: flex;
  height: 48px;
  margin-right: 16px;

  img {
    width: 48px !important;
    height: 48px !important;
    border-radius: 50%;
    max-width: none;
    overflow: hidden;
  }
`;

const BlogPostAuthorContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const BlogPostAuthorName = styled.div`
  display: block;
  font-weight: bold;

  a {
    color: ${(props) => props.theme.titleColor};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    ${media.md`
      font-size: 22px;
      line-height: 1.4;
    `}
  }
`;

const BlogPostAuthorRole = styled.div`
  color: #c4c4c4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export {
  StyledBlogPost,
  BlogPostTitle,
  BlogPostDescription,
  CoverContainer,
  BlogPostMetadata,
  BlogPostDivider,
  BlogPostDuration,
  BlogPostAuthor,
  BlogPostAuthorImage,
  BlogPostAuthorContent,
  BlogPostAuthorName,
  BlogPostAuthorRole,
};
