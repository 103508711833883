import React from "react";
import { PageContainer, UnderlinedLink } from "styles/global.style";
import {
  OuterContainer,
  PostContainer,
  PostDetailsContainer,
  StyledReadPost,
  WrapperContainer,
} from "./index.style";
import { graphql, Link, useStaticQuery } from "gatsby";
import { getBlogPathBySlug } from "utils/path";
import { AllPostQueryResult } from "app-types/blog";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  BlogPostDescription,
  BlogPostTitle,
} from "components/ui/base/BlogPost/index.style";
import SafeLink from "components/core/SafeLink";
import Icon from "components/core/Icon";
import { useTranslation } from "react-i18next";

export interface ReadPostProps {
  slug: string;
}

const ReadPost = (props: ReadPostProps) => {
  const { t } = useTranslation();
  const { allPostResult }: AllPostQueryResult = useStaticQuery(graphql`
    query {
      allPostResult: allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          author {
            node {
              avatar {
                url
              }
              name
              uri
              description
            }
          }
          tags {
            nodes {
              name
              slug
              uri
            }
          }
          categories {
            nodes {
              name
              slug
              uri
            }
          }
          date(formatString: "MMM DD, YYYY")
          uri
          slug
          title
          content
          seo {
            title
            metaDesc
          }
          smallFeaturedImage: featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, height: 300, width: 500)
                }
              }
            }
          }
        }
      }
    }
  `);

  const post = allPostResult.nodes.filter(
    (post) => post.slug === props.slug
  )[0];

  return (
    <StyledReadPost>
      <OuterContainer>
        <WrapperContainer>
          <PageContainer>
            <Link to={getBlogPathBySlug(props.slug)}>
              <PostContainer>
                <GatsbyImage
                  alt={post.title}
                  image={
                    post.smallFeaturedImage.node.localFile.childImageSharp
                      .gatsbyImageData
                  }
                />
                <PostDetailsContainer>
                  <BlogPostTitle level={3}>
                    <SafeLink to={getBlogPathBySlug(post.slug)}>
                      {post.title}
                    </SafeLink>
                  </BlogPostTitle>
                  <BlogPostDescription>{post.seo.metaDesc}</BlogPostDescription>
                  <UnderlinedLink
                    as={SafeLink}
                    to={getBlogPathBySlug(post.slug)}
                  >
                    {t("components.ui.base.ReadPost.readMore")}
                    <Icon type={"arrow-right"} size={16} />
                  </UnderlinedLink>
                </PostDetailsContainer>
              </PostContainer>
            </Link>
          </PageContainer>
        </WrapperContainer>
      </OuterContainer>
    </StyledReadPost>
  );
};

export default ReadPost;
