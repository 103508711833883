import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const StyledReadPost = styled.div`
  margin: 120px 0;
`;

const OuterContainer = styled.div`
  max-width: 95%;
  margin: 0 auto;
  border-radius: 20px;
  position: relative;
`;

const WrapperContainer = styled.div`
  color: ${(props) => props.theme.baseLightColor};
`;

const Divider = styled.span`
  margin: 0 8px;
  color: #fff;
`;

const PostContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.accentLighten95Color};
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  flex-direction: column;

  ${media.md`
      flex-direction: row;
    `}
  .gatsby-image-wrapper {
    border-radius: 16px;
  }
`;

const PostDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  font-size: 16px;

  svg {
    margin-left: 2px;
    transition: all 0.3s ease-in-out;
    stroke: ${(props) => props.theme.accentColor};

    width: 16px;
    height: 16px;
  }
`;

export {
  StyledReadPost,
  OuterContainer,
  WrapperContainer,
  Divider,
  PostDetailsContainer,
  PostContainer,
};
